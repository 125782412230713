import React from "react";
import styled from "styled-components";

import {
  Header,
  Text,
  ListItem,
  Link,
} from "../../components/styled-components";

const HowToDownloadContainer = styled.section`
  margin: 20px 0;
`;

const HowToDownload = () => (
  <HowToDownloadContainer>
    <Header size="Medium">WTF?! How do I download my triggered gif?!?!</Header>
    <ol>
      <ListItem>
        <Text>
          For the moment, you will need to capture your triggered gif with a
          screen recorder
        </Text>
        <ul>
          <ListItem>
            <strong>Windows:</strong>{" "}
            <Link href="https://www.screentogif.com/" target="_blank">
              ScreenToGif
            </Link>
          </ListItem>
          <ListItem>
            <strong>Mac:</strong>{" "}
            <Link href="https://getkap.co/" target="_blank">
              Kap
            </Link>
          </ListItem>
          <ListItem>
            <strong>Linux:</strong>{" "}
            <Link
              href="https://github.com/phw/peek#official-distribution-packages"
              target="_blank"
            >
              Peek
            </Link>
          </ListItem>
        </ul>
      </ListItem>
      <ListItem>Gif download feature coming soon!!</ListItem>
    </ol>
  </HowToDownloadContainer>
);

export { HowToDownload };
