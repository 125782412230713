import React from "react";
import styled, { css } from "styled-components";

import { theme } from "../../components/theme";

const {
  palette: { primary },
} = theme;

const RangeInputWrapper = styled.div`
  margin: 20px 0;
`;

const RangeThumbStyles = css`
  width: 30px;
  height: 30px;
  background: ${primary};
  border-radius: 30px;
  cursor: pointer;
  transition: width 0.1s ease-out, height 0.1s ease-out;

  :hover {
    width: 40px;
    height: 40px;
  }
`;

const RangeInput = styled.input`
  /* Required to style thumb */
  -webkit-appearance: none;

  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #efefef;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    ${RangeThumbStyles}
  }

  &::-moz-range-thumb {
    ${RangeThumbStyles}
  }
`;

const TriggerLevelDisplay = styled.h2`
  text-align: center;
  letter-spacing: 1px;
`;

const TriggerNumber = styled.span`
  font-family: "Trigger", sans-serif;
  font-size: 28px;
  letter-spacing: 2px;
  color: ${primary};
`;

const TriggerLevel = ({ triggerLevel, onTriggerLevelChange }) => {
  return (
    <>
      <RangeInputWrapper>
        <RangeInput
          type="range"
          min="0"
          max="11"
          value={triggerLevel}
          onChange={(event) => {
            onTriggerLevelChange(event.target.value);
          }}
        />
      </RangeInputWrapper>
      <TriggerLevelDisplay>
        Trigger Level: <TriggerNumber>{triggerLevel}</TriggerNumber>
      </TriggerLevelDisplay>
    </>
  );
};

export { TriggerLevel };
