import React, { useState, useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import awesomeFaceImage from "../images/awesome-face.jpg";
import {
  StyledTriggeredImage,
  StyledForm,
  InputWrapper,
  Input,
  Button,
  UploadIconButton,
  Error,
} from "../components/styled-components";
import { HowToDownload } from "../components/how-to-download";
import { TriggerLevel } from "../components/trigger-level";

const TriggeredImage = ({ imageUrl, triggerLevel }) => {
  return <StyledTriggeredImage src={imageUrl} triggerLevel={triggerLevel} />;
};

const UploadImageButton = ({ setImage, setInput }) => {
  let fileReader;

  useEffect(() => {
    fileReader = new FileReader();
    fileReader.onload = function(e) {
      setImage(e.target.result);
    };
  });

  return (
    <>
      <UploadIconButton htmlFor="myFile" />
      <input
        type="file"
        hidden
        id="myFile"
        name="filename"
        accept=".jpg, .jpeg, .png, .gif"
        onChange={(input) => {
          const { files } = input.target;
          const file = files[0];
          if (files && file) {
            fileReader.readAsDataURL(file);
            setInput(file.name);
          }
        }}
      />
    </>
  );
};

const urlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

const Triggered = () => {
  const [imageUrl, setImage] = useState(awesomeFaceImage);
  const [inputUrl, setInput] = useState("");
  const [urlError, setUrlError] = useState("");
  const [triggerLevel, setTriggerLevel] = useState(6);

  return (
    <>
      <TriggeredImage imageUrl={imageUrl} triggerLevel={triggerLevel} />
      <StyledForm
        onSubmit={(event) => {
          event.preventDefault();

          if (!inputUrl.match(urlRegex)) {
            setUrlError("Please enter a valid image Url.");
            return;
          }

          setImage(inputUrl);
          setUrlError("");
        }}
      >
        <InputWrapper>
          <UploadImageButton setImage={setImage} setInput={setInput} />
          <Input
            type="text"
            value={inputUrl}
            placeholder="Enter a non-triggered url..."
            onChange={(event) => setInput(event.target.value)}
          />
          <Button>Triggerify!</Button>
        </InputWrapper>
        {urlError && <Error>{urlError}</Error>}
        <TriggerLevel
          triggerLevel={triggerLevel}
          onTriggerLevelChange={setTriggerLevel}
        />
      </StyledForm>
      <HowToDownload />
    </>
  );
};

const IndexPage = () => (
  <Layout>
    <SEO title="Triggerify.me" />
    <Triggered />
  </Layout>
);

export default IndexPage;
