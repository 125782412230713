import styled, { keyframes } from "styled-components";

import { theme } from "../components/theme";

const {
  palette: { borderGray, lightGray, mediumGray, primary },
  fonts,
  layout: { maxWidth },
} = theme;

// Common components
export const Header = styled.h3(({ size = "" }) => {
  return `
    font-size: ${fonts[`header${size}Size`]};
    margin-bottom: 12px;
  `;
});

export const InputWrapper = styled.div`
  margin: 0 auto;
  border: 1px solid ${borderGray};
  border-radius: 5px;
  max-width: 65vw;
`;

export const Input = styled.input`
  height: 60px;
  width: 60%;
  padding: 16px;

  border: 0;
  border-right: none;
  letter-spacing: 1px;

  ::placeholder {
    color: ${lightGray};
  }
`;

export const Button = styled.button`
  height: 61px;
  width: 30%;

  border: 0;
  background-color: ${primary};
  color: white;
  font-weight: 600;
  letter-spacing: 1px;
  outline: 0;
  cursor: pointer;
`;

export const UploadIconButton = styled.label`
  display: inline-block;
  height: 61px;
  width: 10%;
  padding: 20px;

  border: 0;
  background-color: ${mediumGray};
  cursor: pointer;
  vertical-align: bottom;

  &:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M380.032 133.472l-112-128C264.992 2.016 260.608 0 256 0s-8.992 2.016-12.032 5.472l-112 128c-4.128 4.736-5.152 11.424-2.528 17.152A16.013 16.013 0 00144 160h64v208c0 8.832 7.168 16 16 16h64c8.832 0 16-7.168 16-16V160h64a15.96 15.96 0 0014.56-9.376c2.592-5.728 1.632-12.448-2.528-17.152z'/%3E%3Cpath d='M432 352v96H80v-96H16v128c0 17.696 14.336 32 32 32h416c17.696 0 32-14.304 32-32V352h-64z'/%3E%3C/svg%3E");
    display: block;
    width: 20px;
    height: 20px;
    opacity: 0.3;
    margin: 0 auto;
  }
`;

export const Text = styled.p`
  font-size: ${fonts.textSize};
`;

export const ListItem = styled.li`
  font-size: ${fonts.textSize};
  margin-bottom: calc(1.45rem / 4);
`;

export const Link = styled.a`
  color: ${primary};
  text-decoration: none;
  border-bottom: 1px dotted ${primary};
`;

export const Error = styled.span`
  display: block;
  margin: 10px auto;
  max-width: 50vw;
  color: ${primary};
`;

// Specific components
export const StyledForm = styled.form`
  margin: 0 auto 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eee;
  max-width: ${maxWidth};
`;

export const trigger = keyframes`
  0% { transform: translate(16px, 8px); }
  5% { transform: translate(-8px, -16px); }
  10% { transform: translate(-32px, 0px); }
  15% { transform: translate(0px, 16px); }
  20% { transform: translate(8px, -8px); }
  25% { transform: translate(-8px, 16px); }
  30% { transform: translate(-32px, 8px); }
  35% { transform: translate(16px, 8px); }
  40% { transform: translate(-8px, -8px); }
  45% { transform: translate(16px, 16px); }
  50% { transform: translate(8px, -16px); }
  55% { transform: translate(16px, 8px); }
  60% { transform: translate(-8px, -16px); }
  65% { transform: translate(-32px, 0px); }
  70% { transform: translate(0px, 16px); }
  75% { transform: translate(8px, -8px); }
  80% { transform: translate(-8px, 16px); }
  85% { transform: translate(-32px, 8px); }
  90% { transform: translate(16px, 8px); }
  95% { transform: translate(-8px, -8px); }
  100% { transform: translate(16px, 16px); }
`;

export const StyledTriggeredImage = styled.img`
  display: block;
  margin: 100px auto;

  animation-name: ${trigger};
  animation-duration: calc(0.5s / ${({ triggerLevel }) => triggerLevel / 10});
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;
